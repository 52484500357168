import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import useFetch from '../clients/features/get';
import Unknown from '../clients/data/unknown.png';
import { Placeholder } from 'react-bootstrap';

export default function Islands() {
    const photos = [
        {
            src: "https://media-cdn.tripadvisor.com/media/photo-c/1280x250/0f/63/53/ae/le-trous-du-prophete.jpg",
            srcset: "https://media-cdn.tripadvisor.com/media/photo-c/1280x250/0f/63/53/ae/le-trous-du-prophete.jpg?auto=webp&width=300 300w, https://media-cdn.tripadvisor.com/media/photo-c/1280x250/0f/63/53/ae/le-trous-du-prophete.jpg?auto=webp&width=600 600w, https://media-cdn.tripadvisor.com/media/photo-c/1280x250/0f/63/53/ae/le-trous-du-prophete.jpg?auto=webp&width=1200 1200w",
            sizes: "(max-width: 600px) 300px, (max-width: 1000px) 600px, 1200px",
            alt: "Ngazidja"
        },
        {
            src: "https://media-cdn.tripadvisor.com/media/photo-c/1280x250/05/51/11/b3/nioumachoua-beach.jpg",
            srcset: "https://media-cdn.tripadvisor.com/media/photo-c/1280x250/05/51/11/b3/nioumachoua-beach.jpg?auto=webp&width=300 300w, https://media-cdn.tripadvisor.com/media/photo-c/1280x250/05/51/11/b3/nioumachoua-beach.jpg?auto=webp&width=600 600w, https://media-cdn.tripadvisor.com/media/photo-c/1280x250/05/51/11/b3/nioumachoua-beach.jpg?auto=webp&width=1200 1200w",
            sizes: "(max-width: 600px) 300px, (max-width: 1000px) 600px, 1200px",
            alt: "Moheli"
        },
        {
            src: "https://media-cdn.tripadvisor.com/media/photo-s/02/2a/89/a9/filename-moya-comores.jpg",
            srcset: "https://media-cdn.tripadvisor.com/media/photo-s/02/2a/89/a9/filename-moya-comores.jpg?auto=webp&width=300 300w, https://media-cdn.tripadvisor.com/media/photo-s/02/2a/89/a9/filename-moya-comores.jpg?auto=webp&width=600 600w, https://media-cdn.tripadvisor.com/media/photo-s/02/2a/89/a9/filename-moya-comores.jpg?auto=webp&width=1200 1200w",
            sizes: "(max-width: 600px) 300px, (max-width: 1000px) 600px, 1200px",
            alt: "Anjouan"
        }
    ];

    const { data, loading, error } = useFetch("https://api.komorotel.com/api/hotels/countByIsland?islands=Ngazidja,Moheli,Anjouan");

    // Styles pour appliquer la police Montserrat Hand
    const titleStyle = {
        fontFamily: "'Montserrat', cursive",
        fontSize: '2rem',
        color: 'primary',  // Couleur personnalisée pour les titres
    };

    const overlayTitleStyle = {
        fontFamily: "'Montserrat', cursive",
        fontSize: '1.75rem',
        color:'white',
        fontWeight: 'bold',
    };

    const overlayTextStyle = {
        fontFamily: "'Roboto', cursive",
        color:'white',
        fontSize: '1.25rem',
    };

    return loading ? (
        <div className='py-5 bg-secondary'>
            <Container>
                <h3 className='text-center text-primary' style={titleStyle}>Choisissez quelle sera votre destination</h3>
                <Row className='gy-5 mt-2'>
                    {photos.map((photo, index) => (
                        <Col md={{ span: 4 }} key={index}>
                            <Card className='mb-5'>
                                <Card.Img variant="top" src={Unknown} style={{ height: '200px', objectFit: 'cover' }} />
                                <Card.Body>
                                    <Placeholder as={Card.Title} animation="glow">
                                        <Placeholder xs={6} />
                                    </Placeholder>
                                    <Placeholder as={Card.Text} animation="glow">
                                        <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                        <Placeholder xs={6} /> <Placeholder xs={8} />
                                    </Placeholder>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    ) : (
        <div className='py-5 bg-secondary'>
            <Container>
                <h3 className='text-center text-primary fw-bold' style={titleStyle}>Choisissez quelle sera votre destination</h3>
                <Row className='gy-5 mt-2'>
                    {data && photos.map((photo, index) => (
                        <Col md={{ span: 4 }} key={index}>
                            <Card as={Link} to={`/island/${photo.alt}`} className="bg-dark text-white">
                                <picture>
                                    <source srcSet={photo.srcset} sizes={photo.sizes} type="image/webp" />
                                    <img
                                        src={photo.src}
                                        alt={photo.alt}
                                        className="object-fit-cover"
                                        height="250px"
                                        width="100%"
                                        loading="lazy"
                                    />
                                </picture>
                                <Card.ImgOverlay>
                                    <div className="position-absolute bottom-0 start-0 w-100 rounded py-lg-1">
                                        <Card.Title className='m-0 p-0 ms-4' style={overlayTitleStyle}>{photo.alt}</Card.Title>
                                        <Card.Text className='m-0 ms-4 mb-3' style={overlayTextStyle}>{data[index]} hôtels disponibles</Card.Text>
                                    </div>
                                </Card.ImgOverlay>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
}
