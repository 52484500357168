import { Button, Col, Row, Form } from "react-bootstrap";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../../components/Header";

export default function SignupBus() {
  const [validated, setValidated] = useState(false);
  const [credidentials, setCredidentials] = useState({
    email: "",
    password: "",
    name: "",
    phoneNumber: ""
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  // Fonction de gestion des changements dans le formulaire
  const handleChange = (e) => {
    setCredidentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  // Fonction de validation du formulaire
  const validateForm = () => {
    const errors = {};

    // Vérifier que l'email est valide
    if (!credidentials.email || !/\S+@\S+\.\S+/.test(credidentials.email)) {
      errors.email = "Veuillez entrer un email valide.";
    }

    // Vérifier que le mot de passe respecte les critères
    if (!credidentials.password || credidentials.password.length < 8) {
      errors.password = "Le mot de passe doit contenir au moins 8 caractères.";
    }

    // Vérifier que le nom de l'entreprise est renseigné
    if (!credidentials.name) {
      errors.name = "Le nom de l'entreprise est requis.";
    }

    // Vérifier que le numéro de téléphone est valide
    if (!credidentials.phoneNumber || !/^\d{10}$/.test(credidentials.phoneNumber)) {
      errors.phoneNumber = "Veuillez entrer un numéro de téléphone valide.";
    }

    return errors;
  };

  // Fonction pour soumettre le formulaire
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validation des champs du formulaire
    const formErrors = validateForm();
    setErrors(formErrors);

    // Si des erreurs existent, ne pas envoyer le formulaire
    if (Object.keys(formErrors).length > 0) {
      setValidated(true);
      return;
    }

    try {
      const res = await axios.post('https://api.komorotel.com/api/business/register', {
        email: credidentials.email,
        name: credidentials.name,
        phoneNumber: credidentials.phoneNumber,
        password: credidentials.password
      });

      alert("Un e-mail vous a été envoyé, veuillez l'ouvrir afin de vous connecter.");
      navigate("/business/login");
    } catch (err) {
      console.error(err);
      alert("Une erreur est survenue lors de l'enregistrement.");
    }
  };

  return (
    <div>
       <Header disabled={true}/>

      <Row className="pt-6">
        <Col sm={12} className="w-100 d-flex flex-column align-items-center justify-content-center">
          <h1 className="text-uppercase text-dark fw-bold text-center">
            <span className="bg-primary p-2 text-center rounded-3 text-white m-1">Entreprise</span>
          </h1>

          <div className="d-flex flex-column border shadow-sm mt-4 p-4 mx-3 rounded-3 border h-100">
            <p className="fw-bold fs-5">Se connecter ou créer un compte</p>
            <p className="fw-light">2- Entrez vos informations afin de pouvoir et profiter de nos services</p>
            <Form noValidate validated={validated} onSubmit={handleSubmit} className="p-3">
              
              {/* Champ Email */}
              <Form.Group className="mb-2" md="4" controlId="email">
                <Form.Label className="fw-bold">Adresse e-mail</Form.Label>
                <Form.Control
                  required
                  type="email"
                  onChange={handleChange}
                  placeholder="E-mail"
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>

              {/* Champ Mot de passe */}
              <Form.Group className="mb-2" md="4" controlId="password">
                <Form.Label className="fw-bold">Mot de passe</Form.Label>
                <Form.Control
                  required
                  type="password"
                  onChange={handleChange}
                  placeholder="Mot de passe"
                  isInvalid={!!errors.password}
                />
                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
              </Form.Group>

              {/* Champ Nom de l'entreprise */}
              <Form.Group className="mb-2" md="4" controlId="name">
                <Form.Label className="fw-bold">Nom de l'entreprise</Form.Label>
                <Form.Control
                  required
                  type="text"
                  onChange={handleChange}
                  placeholder="Nom de l'entreprise"
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>

              {/* Champ Numéro de téléphone */}
              <Form.Group className="mb-2" md="4" controlId="phoneNumber">
                <Form.Label className="fw-bold">Numéro de téléphone</Form.Label>
                <Form.Control
                  required
                  type="text"
                  onChange={handleChange}
                  placeholder="Numéro de téléphone"
                  isInvalid={!!errors.phoneNumber}
                />
                <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
              </Form.Group>

              <div className="d-flex flex-column">
                <Button type="submit" className="mt-3 text-white">Confirmer</Button>
              </div>
            </Form>

            <p className="fw-bold text-center">
              Déjà un compte ? <Link to="/business/login">Se connecter</Link>
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
}
