import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import CarouselSlide1 from '../data/carousel/1.webp';
import CarouselSlide2 from '../data/carousel/2.jpg';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Islands from '../../components/Islands';
import PropertyList from '../../components/PropertyList';
import Footer from '../../components/Footer';

function App() {
  const [openDestination, setOpenDestination] = useState(false);

  const image = [
    "https://cdn.aspas-nature.org/wp-content/uploads/2022/01/Tortue-verte-wikimedia-commons.jpg",
    "https://www.vanilla-islands.org/wp-content/uploads/2015/12/accueil-comores-plage-barque.jpg",
    "https://horizonsnomades.fr/wp-content/uploads/les-iles-des-comores-4.jpg",
  ];




  return (
    <div onClick={() => setOpenDestination(false)}>
      <Header openDestination={openDestination} setOpenDestination={setOpenDestination} />

      {/* Carousel principal (bureau) */}
      <Carousel className='mt-7 d-none d-md-block pb-4 px-4'>
        <Carousel.Item>
          <Image
            src={CarouselSlide1}
            alt="Premier slide mobile"
            className="w-100 d-inline-block object-fit-cover"
            height="700px"
            width="100%"
            loading="eager" 
            srcSet={`${CarouselSlide1} 1200w, /static/media/1.4ff898c9a87c122e2a39.webp 700w, /static/media/1.4ff898c9a87c122e2a39.webp 800w`}
            sizes="(max-width: 600px) 300px, (max-width: 1000px) 600px, 1200px"
          />
          <Carousel.Caption className="d-none d-md-block">
            
          </Carousel.Caption>
        </Carousel.Item>

        {image.map((src, i) => (
          <Carousel.Item key={i}>
            <Image
              src={src}
              alt={`Slide ${i + 2}`}
              className="w-100 d-inline-block object-fit-cover"
              height="700px"
              width="100%"
              loading="lazy"  
            />
          </Carousel.Item>
        ))}
      </Carousel>

      {/* Carousel pour mobile */}
      <Carousel className="d-md-none pb-4">
        <Carousel.Item>
          <Image
            src={CarouselSlide1}
            alt="Premier slide mobile"
            className="w-100 d-inline-block object-fit-cover"
            height="300px"
            width="100%"
            loading="eager" 
            srcSet={`${CarouselSlide1} 1200w, /static/media/1.4ff898c9a87c122e2a39.webp 700w, /static/media/1.4ff898c9a87c122e2a39.webp 800w`}
            sizes="(max-width: 600px) 300px, (max-width: 1000px) 600px, 1200px"
          />
          <Carousel.Caption className="d-none d-md-block">
            
          </Carousel.Caption>
        </Carousel.Item>

        {image.map((src, i) => (
          <Carousel.Item key={i}>
            <Image 
              src={src}
              alt={`Slide ${i + 2} mobile`}
              className="w-100 d-inline-block object-fit-cover"
              height="300px"
              width="100%"
              loading="lazy"
            />
          </Carousel.Item>
        ))}
      </Carousel>

      <Islands />
      <PropertyList />
      <Footer />
    </div>
  );
}

export default App;
