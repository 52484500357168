import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../../components/Header";

export default function Signup() {
  const [validated, setValidated] = useState(false);
  const [credidentials, setCredidentials] = useState({
    email: "",
    password: "",
    name: "",
    surname: ""
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredidentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const validateForm = () => {
    const errors = {};

    // Vérifier que l'email est valide
    if (!credidentials.email || !/\S+@\S+\.\S+/.test(credidentials.email)) {
      errors.email = "Veuillez entrer un email valide.";
    }

    // Vérifier que le mot de passe respecte les critères
    if (!credidentials.password || credidentials.password.length < 8) {
      errors.password = "Le mot de passe doit contenir au moins 8 caractères.";
    }

    // Vérifier les champs de nom et prénom
    if (!credidentials.name) {
      errors.name = "Le prénom est requis.";
    }
    if (!credidentials.surname) {
      errors.surname = "Le nom est requis.";
    }

    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const formErrors = validateForm();
    setErrors(formErrors);

    // Si il y a des erreurs, ne pas soumettre le formulaire
    if (Object.keys(formErrors).length > 0) {
      setValidated(true);
      return;
    }

    try {
      const res = await axios.post('https://api.komorotel.com/api/auth/register', {
        email: credidentials.email,
        name: credidentials.name,
        surname: credidentials.surname,
        password: credidentials.password
      });

      alert("Un e-mail vous a été envoyé, veuillez l'ouvrir afin de vous connecter.");
      navigate(`/login/${res.data.user.id}`);
    } catch (err) {
      console.error(err);
      alert("Une erreur est survenue lors de l'enregistrement.");
    }
  };

  return (
    <div>
      <Header disabled={true}/>

      <Row className="pt-4">
        
        <Col xs={12} className="w-100 d-flex flex-column align-items-center justify-content-center">
          <div className="d-flex flex-column border shadow-sm p-4 m-3 rounded-3 border h-100">
            <p className="fw-bold fs-5">Créer un compte</p>
            <p className="fw-light">Entrez vos informations afin de pouvoir et profiter de nos services</p>
            <Form noValidate validated={validated} onSubmit={handleSubmit} className="p-3">
              
              {/* Champ Email */}
              <Form.Group className="mb-2" md="4" controlId="email">
                <Form.Label className="fw-bold">Adresse e-mail</Form.Label>
                <Form.Control
                  required
                  type="email"
                  onChange={handleChange}
                  placeholder="E-mail"
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>

              {/* Champ Mot de passe */}
              <Form.Group className="mb-2" md="4" controlId="password">
                <Form.Label className="fw-bold">Mot de passe</Form.Label>
                <Form.Control
                  required
                  type="password"
                  onChange={handleChange}
                  placeholder="Mot de passe"
                  isInvalid={!!errors.password}
                />
                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
              </Form.Group>

              {/* Champ Prénom */}
              <Form.Group className="mb-2" md="4" controlId="name">
                <Form.Label className="fw-bold">Prénom</Form.Label>
                <Form.Control
                  required
                  type="text"
                  onChange={handleChange}
                  placeholder="Prénom"
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>

              {/* Champ Nom */}
              <Form.Group className="mb-2" md="4" controlId="surname">
                <Form.Label className="fw-bold">Nom</Form.Label>
                <Form.Control
                  required
                  type="text"
                  onChange={handleChange}
                  placeholder="Nom"
                  isInvalid={!!errors.surname}
                />
                <Form.Control.Feedback type="invalid">{errors.surname}</Form.Control.Feedback>
              </Form.Group>

              <div className="d-flex flex-column">
                <Button type="submit" className="mt-3 text-white">Confirmer</Button>
              </div>
            </Form>

            <p className="fw-bold text-center">
              Déjà un compte ? <Link to="/login">Se connecter</Link>
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
}
