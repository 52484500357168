import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import useFetch from '../clients/features/get';
import Unknown from '../clients/data/unknown.png';
import { Placeholder } from 'react-bootstrap';

export default function PropertyList() {
    const { data, loading, error } = useFetch("https://api.komorotel.com/api/hotels/countByType");
    
    const images = [
        {
            src: "https://content.r9cdn.net/rimg/dimg/d3/d4/31e1fcd5-city-53679-16a6867b34d.jpg?",
            srcset: "https://content.r9cdn.net/rimg/dimg/d3/d4/31e1fcd5-city-53679-16a6867b34d.jpg?auto=webp&width=300 300w, https://content.r9cdn.net/rimg/dimg/d3/d4/31e1fcd5-city-53679-16a6867b34d.jpg?auto=webp&width=600 600w, https://content.r9cdn.net/rimg/dimg/d3/d4/31e1fcd5-city-53679-16a6867b34d.jpg?auto=webp&width=1200 1200w",
            sizes: "(max-width: 600px) 300px, (max-width: 1000px) 600px, 1200px",
            alt: "City Image"
        },
        {
            src: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/353470429.jpg?k=ca5a6d8cfb68a3caf5bd51b62fb3efc01911695653153cbee0816bd3f46b0935&o=&hp=1",
            srcset: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/353470429.jpg?k=ca5a6d8cfb68a3caf5bd51b62fb3efc01911695653153cbee0816bd3f46b0935&o=&hp=1&width=300 300w, https://cf.bstatic.com/xdata/images/hotel/max1024x768/353470429.jpg?k=ca5a6d8cfb68a3caf5bd51b62fb3efc01911695653153cbee0816bd3f46b0935&o=&hp=1&width=600 600w, https://cf.bstatic.com/xdata/images/hotel/max1024x768/353470429.jpg?k=ca5a6d8cfb68a3caf5bd51b62fb3efc01911695653153cbee0816bd3f46b0935&o=&hp=1&width=1200 1200w",
            sizes: "(max-width: 600px) 300px, (max-width: 1000px) 600px, 1200px",
            alt: "Hotel Image"
        },
        {
            src: "https://a0.muscache.com/im/pictures/81dca5d6-5a86-49bc-8eca-4a8610a07d27.jpg",
            srcset: "https://a0.muscache.com/im/pictures/81dca5d6-5a86-49bc-8eca-4a8610a07d27.jpg?auto=webp&width=300 300w, https://a0.muscache.com/im/pictures/81dca5d6-5a86-49bc-8eca-4a8610a07d27.jpg?auto=webp&width=600 600w, https://a0.muscache.com/im/pictures/81dca5d6-5a86-49bc-8eca-4a8610a07d27.jpg?auto=webp&width=1200 1200w",
            sizes: "(max-width: 600px) 300px, (max-width: 1000px) 600px, 1200px",
            alt: "Airbnb Image"
        }
    ];

    // Style pour appliquer la police à certains éléments
    const titleStyle = {
        fontFamily: "'Montserrat', cursive",
        fontSize: '2rem',  // Ajuste la taille de la police selon tes préférences
        color: 'primary',   // Couleur personnalisée pour le titre
    };

    return loading ? (
        <Container className='my-5 bg-white'>
            <h3 className='text-center' style={titleStyle}>Choisissez en fonction du type</h3>
            <Row className='gy-5 mt-2'>
                {images.map((image, i) => (
                    <Col md={{ span: 4 }} key={i}>
                        <Card className='mb-5'>
                            <Card.Img variant="top" src={Unknown} style={{ height: '200px' }} />
                            <Card.Body>
                                <Placeholder as={Card.Title} animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                                <Placeholder as={Card.Text} animation="glow">
                                    <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                    <Placeholder xs={6} /> <Placeholder xs={8} />
                                </Placeholder>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    ) : (
        <Container className='my-5 bg-white'>
            <h3 className='text-center fw-bold text-primary' style={titleStyle}>Choisissez en fonction du type</h3>
            <Row className='gy-5 mt-2'>
                {data && images.map((image, i) => (
                    <Col md={{ span: 4 }} key={i}>
                        <Card as={Link} to={data[i]?.type === "Appartement&Maison" ? `/type/Appartement&Maison/warning` : `/type/${data[i]?.type}`} className="border-none">
                            <picture>
                                <source srcSet={image.srcset} sizes={image.sizes} type="image/webp" />
                                <img src={image.src} alt={image.alt} className="object-fit-cover" height="200px" width="100%" loading="lazy" />
                            </picture>
                            <Card.Title className='text-center'>{data[i]?.type}</Card.Title>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}
