// Import des dépendances nécessaires
import { useParams } from "react-router-dom"; // Pour récupérer les paramètres de l'URL (ex: hotelId)
import useFetch from "../../clients/features/get"; // Hook personnalisé pour récupérer des données via axios
import { Button, Form, Card, Col, Container, Dropdown, Row } from "react-bootstrap"; // Composants de React Bootstrap
import { MdBedroomParent } from "react-icons/md"; // Icône représentant une chambre
import { useContext, useEffect, useState } from "react"; // Pour gérer l'état et les effets de bord
import { FaCalendarAlt } from "react-icons/fa"; // Icône pour le calendrier
import { DateRange } from "react-date-range"; // Composant pour sélectionner des plages de dates
import { format } from "date-fns"; // Utilisé pour formater les dates
import axios from "axios"; // Bibliothèque pour envoyer des requêtes HTTP
import { AuthContest } from "../../clients/utils/AuthContext"; // Context pour gérer l'authentification

// Définition du composant principal Rooms
export default function Rooms() {
    // Récupère l'ID de l'hôtel depuis les paramètres de l'URL
    const hotelParams = useParams();
    const hotelId = hotelParams.hotelId;

    // États locaux pour gérer différentes modales et interactions utilisateur
    const [openChangeValue, setOpenChangevalue] = useState(false);
    const [openReserve, setOpenReserve] = useState(false);
    const [openCreateRoom, setOpenCreateRoom] = useState(false);
    const [dupliacte, setDuplicate] = useState(false);
    const [dupliacte2, setDuplicate2] = useState(false);
    const [hotel, setHotel] = useState()
    const [searchTerm, setSearchTerm] = useState([]); // Termes de recherche pour filtrer les chambres
    const [fillRoom, setFillRoom] = useState(); // Pour stocker les dates de réservation sélectionnées

    // Données du formulaire pour la réservation
    const [formData, setFormData] = useState({
        nom: '',
        prenom: '',
        email: '',
        telephone: '',
        roomType: '',
        roomId: '',
        roomNumberId: '',
        price: null
    });

     // Récupère les données des chambres pour l'hôtel via un hook personnalisé
     const { data, loading, error, refreshData } = useFetch(`https://api.komorotel.com/api/hotels/rooms/${hotelId}`);


    // Récupération des données de l'hotel
    useEffect(() => {
        async function fetchMyAPI(){
            const res = await fetch(`https://api.komorotel.com/api/hotels/find/${hotelId}`).then((res) => res.json())
            setHotel(res)
        }
        fetchMyAPI()
      }, [loading])

    // Gestion des changements dans les champs du formulaire de réservation
    const handleChangeForm = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Récupère l'utilisateur connecté depuis le contexte d'authentification
    const { user } = useContext(AuthContest);

    // Met à jour les termes de recherche en fonction des entrées de l'utilisateur
    const updateSearch = (e, index) => {
        let newState = [...searchTerm];
        newState[index] = { number: e.target.value };
        setSearchTerm(newState);
    };

    // États pour gérer la sélection de la plage de dates de réservation
    const [index, setIndex] = useState("");
    const [date, setDates] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }]);

    // Fonction utilitaire pour obtenir toutes les dates entre `start` et `end`
    const getDateInRange = (start, end) => {
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);
        const date = new Date(start.getTime());
        const list = [];

        while (date <= end) {
            list.push(new Date(date).getTime()); // Ajoute chaque date dans la liste
            date.setDate(date.getDate() + 1); // Incrémentation de la date
        }

        return list;
    };
    const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24
    function dayDifference(date1, date2){
        const timeDiff= Math.abs(date2.getTime()-date1.getTime())
        const diffDays= Math.ceil(timeDiff / MILLISECONDS_PER_DAY)
        return diffDays
    }
    
    var days =date ? dayDifference(date[0]?.endDate, date[0]?.startDate): undefined

    // Génère la liste des dates de réservation en fonction de la plage sélectionnée
    const allDates = getDateInRange(date[0].startDate, date[0].endDate);

    // Fonction pour gérer la soumission d'une réservation
    const handleClick = async (e) => {
        e.preventDefault()
        // On prépare les données de réservation
        setFillRoom([allDates, `${hotel.name} - ${formData.nom} ${formData.prenom}`]);
        if (fillRoom) {
            const reservationData = {
                guest: {
                    name: `${hotel.name} - ${formData.nom} ${formData.prenom}`,
                    email: formData.email,
                    phoneNumber: formData.telephone,
                    id: user._id
                },
                hotel: {
                    hotelName: hotel.name,
                    hotelId: hotelId
                },
                room: {
                    roomType: formData.roomType,
                    id: formData.roomId,
                    roomNumberId: formData.roomNumberId,
                    checkInDate: allDates[0],
                    checkOutDate: allDates[allDates.length - 1],
                    alldate: fillRoom
                },
                price: formData.price * days,
                admin: true
            };

            // Envoi des données de réservation via une requête PUT
            try {
                const res = await axios({
                    method: 'put',
                    withCredentials: true,
                    url: `https://api.komorotel.com/api/rooms/availability/admin/${formData.roomNumberId}/${hotelId}`,
                    data: {
                        dates: fillRoom, 
                        reservationData: reservationData
                    }
                }); 
                setOpenReserve(false);
                refreshData(); // Rafraîchissement des données après la réservation
               return res.data; 
            } catch (err) {
                console.log(err); // Gestion des erreurs de la requête
            }
        }
    };

    // Fonction pour supprimer la disponibilité d'une chambre pour certaines dates
    const deleteRoomStatus = async (roomId, dates) => {
        try {
            const res = await axios({
                method: 'delete',
                withCredentials: true,
                url: `https://api.komorotel.com/api/rooms/availability/delete/${roomId}`,
                data: { dates: dates }
            });
            setOpenReserve(false);
            refreshData(); // Rafraîchissement des données après suppression
            return res.data;
        } catch (err) {
            console.log(err); // Gestion des erreurs de la requête
        }
    };

    // Fonction pour vérifier si une chambre est disponible pour les dates sélectionnées
    const isAvailable = (roomNumber) => {
        const isFound = roomNumber.unavailableDates.some((element) =>
            element[0].some((date) =>
                allDates.includes(new Date(date).getTime()) // Vérifie si les dates de la chambre sont occupées
            )
        );
        return !isFound; // Si une date est trouvée, la chambre est indisponible
    };

   
    // Fonction pour gérer la soumission d'un formulaire de création de chambre
    const handleSubmit = async (event) => {
        const title = document.getElementById("newName").value;
        const desc = document.getElementById("newDesc").value;
        const maxPeople = document.getElementById("newMaxPeople").value;
        const price = document.getElementById("newPrice").value;

        try {
            const res = await fetch(`https://api.komorotel.com/api/rooms/${hotelId}`, {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    title: title,
                    desc: desc,
                    maxPeople: maxPeople,
                    price: price,
                    roomNumbers: searchTerm
                })
            });
            refreshData(); // Rafraîchissement des données après la création
            setOpenCreateRoom(false);
        } catch (err) {
            console.log(err); // Gestion des erreurs de la requête
        }
    };

    // Fonction pour modifier les informations d'une chambre existante
    const handleSearch = async (roomId) => {
        const title = document.getElementById("name").value;
        const desc = document.getElementById("desc").value;
        const maxPeople = document.getElementById("maxPeople").value;

        const res = await axios({
            method: 'put',
            withCredentials: true,
            url: `https://api.komorotel.com/api/rooms/${roomId}`,
            headers: { 'Content-Type': 'application/json' },
            data: { title: title, desc: desc, maxPeople: maxPeople }
        });

        refreshData(); // Rafraîchissement des données après modification
        setOpenChangevalue(false);
        setIndex(""); // Réinitialise l'index de la chambre modifiée
    };

    // Fonction pour supprimer une chambre existante
    const handleDelete = async (roomId) => {
        await fetch(`https://api.komorotel.com/api/rooms/${roomId}/${hotelId}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        }).catch(error => console.log(error));

        refreshData(); // Rafraîchissement des données après suppression
        setOpenChangevalue(false);
        setIndex(""); // Réinitialise l'index après suppression
    };

    // Fonction pour changer le type de chambre, et ouvrir la modale de réservation
    const handleChange = (roomType, roomId, roomNumberId, price) => {
        setFormData({ ...formData, roomType: roomType, roomId: roomId, roomNumberId: roomNumberId, price: price });
        setOpenChangevalue(false);
        setOpenReserve(true); // Ouvre la modale de réservation
    };


   

    
    return(
    loading ?<div class="spinner-border bg-secondary align-middle text-primary" role="status"> </div> :
        <div className="bg-secondary border p-4 shadow-sm w-75  rounded-3 d-flex flex-column">
            <div className="d-flex mb-4"><h3>Chambres</h3> <p className="m-0 mt-1 ms-2 text-primary p-1 bg-white shadow-sm h-75 rounded-pill" onClick={()=> setOpenCreateRoom(true)} style={{fontSize:"14px", cursor:"pointer"}}>+ Ajouter une chambre</p></div>
            {data?.map((room, index)=>
             
                <div className="bg-white mb-6 text-wrap p-3 d-flex flex-column align-items-center shadow-sm rounded-pill" onClick={()=> {setOpenChangevalue(true); setIndex(index)} } style={{cursor:"pointer"}}>
                    <Row className="d-flex flex-column align-items-center">
                        <p className="fw-bold mt-3 fs-5 "><MdBedroomParent /> {room.title}</p>
                        <p className="text-center fs-5">{room.desc}</p>

                    </Row>
                    <Row>
                        {room.roomNumbers.map((roomNumber)=>
                    isAvailable(roomNumber) ?
                        <Col className="bg-primary me-5 d-flex flex-column mt-3 rounded-3">
                            <p className="fw-bold fs-4 text-center text-white mb-3">{roomNumber.number}</p>
                            <p className="text-white text-center">Disponible</p>
                        </Col> :
                        <Col className="bg-danger me-5 d-flex flex-column mt-3 rounded-3">
                            <p className="fw-bold fs-4 text-center text-white mb-3">{roomNumber.number}</p>
                            <p className="text-white text-center">Indisponible</p>
                        </Col>
                        )}
                        
                    </Row>
                    


                </div>)}
            {openCreateRoom&&
                <div className=" position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center z-2" style={{"backgroundColor":"rgba(0, 0, 0, 0.418)"}}>
                <Container  className="bg-white d-none d-md-block w-50 h-75 p-2 rounded overflow-auto ">
                    <button className="position-fixed start-75 btn-close" type="button" onClick={()=> {setOpenCreateRoom(false)} }  aria-label="Close"></button>
                    <span className="fs-5 fw-bold text-primary m-2">Ajouter une chambre</span>
                    <div className="d-flex flex-column mx-3 mt-3">
                        
                            <div className="d-flex flex-column mx-5  pb-6 border-bottom">
                                <p className="fw-bold text-decoration-underline fs-4">Informations</p>
                                <Row className="d-flex">
                                    <Col className=" ">
                                        <p className="m-0   me-2 fw-bold">Nom de la chambre:</p>
                                        <input className="m-0 fs-6   text-center rounded-3  fw-bold" id="newName" />
                                    </Col>

                                    <Col className="   ">
                                        <p className="m-0   me-2 fw-bold">Description:</p>
                                        <input className="m-0 fs-6  text-center rounded-3  " id="newDesc" />
                                    </Col>

                                    <Col className="   ">
                                        <p className="m-0   me-2 fw-bold">Personnes max:</p>
                                        <input className="m-0 fs-6 text-center rounded-3 mb-3 fw-bold" type="number" id="newMaxPeople"  />                                    
                                    </Col>
                                    <Col className="  ">
                                        <p className="m-0  fs-6 me-2 fw-bold">Prix (en €):</p>
                                        <input className="m-0 fs-6 text-center rounded-3 fw-bold" type="number" id="newPrice"  />                                    
                                    </Col>

                                </Row>
                                <p className="fw-bold text-decoration-underline fs-4">Chambres</p>
                                
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <Row className="d-flex">
                                            <Col className=" ">
                                                <p className="m-0   me-2 fw-bold">Numéro de la chambre:</p>
                                                <input  className="m-0 fs-6   text-center rounded-3  fw-bold"
                                                        onChange={(e) => updateSearch(e, 0)}/>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex">
                                            <Col className=" ">
                                                <p className="m-0   me-2 fw-bold">Numéro de la chambre:</p>
                                                <input  className="m-0 fs-6   text-center rounded-3  fw-bold"
                                                        onChange={(e) => updateSearch(e, 1)}/>
                                            </Col>
                                        </Row>
                                    </div>
                                    {dupliacte&&
                                    <div>
                                        <Row className="d-flex">
                                            <Col className=" ">
                                                <p className="m-0   me-2 fw-bold">Numéro de la chambre:</p>
                                                <input  className="m-0 fs-6   text-center rounded-3  fw-bold"
                                                        onChange={(e) => updateSearch(e, 2)}/>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex">
                                            <Col className=" ">
                                                <p className="m-0   me-2 fw-bold">Numéro de la chambre:</p>
                                                <input  className="m-0 fs-6   text-center rounded-3  fw-bold"
                                                        onChange={(e) => updateSearch(e, 3)}/>
                                            </Col>
                                        </Row>
                                    </div>}
                                </div>

                                
                                <p  className="m-0 mt-2 text-primary p-1 bg-secondary text-center w-50 shadow-sm h-75 rounded-pill"
                                    onClick={()=> dupliacte? setDuplicate2(true) : setDuplicate(true) }
                                    style={{fontSize:"14px", cursor:"pointer"}}>
                                    + Ajouter des salles pour la chambre</p>

                                    
                                    
                                </div>
                        
                    </div>
                    <div className="d-flex justify-content-between w-100">
                                        <Button className="bg-danger text-white fw-bold me-1 w-50 mt-5" onClick={() =>setOpenCreateRoom(false)}>Annuler</Button>
                                        <Button className="bg-primary text-white fw-bold w-50 ms-1 mt-5" onClick={() => handleSubmit()} >Valider</Button>
                                    </div>
    
                </Container>
                </div> }
            {openChangeValue && 
                <div className=" position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center z-2" style={{"backgroundColor":"rgba(0, 0, 0, 0.418)"}}>
                <Container  className="bg-white d-none d-md-block w-50 h-75 p-2 rounded overflow-auto ">
                    <button className="position-fixed start-75 btn-close" type="button" onClick={()=> {setOpenChangevalue(false); setIndex("")} }  aria-label="Close"></button>
                    <span className="fs-5 fw-bold text-primary ms-4">Modifier la chambre</span>
                    <div className="d-flex flex-column mx-3 mt-3">
                        
                            <div className="d-flex justify-content-between mx-5 mt-2 pb-4 mb-4 border-bottom">
                                <div className="w-100 d-flex flex-column align-items-center ">
                                    <div className="d-flex w-100">
                                        <p className="m-0 w-25 fs-5 me-2 fw-bold">Nom de la chambre:</p>
                                        <input className="m-0 fs-5 w-75 text-center rounded-3 mb-4 fw-bold" id="name" defaultValue={data[index].title}/>
                                    </div>

                                    <div className="d-flex w-100">
                                        <p className="m-0 w-25 fs-5 me-2 fw-bold">Description :</p>
                                        <input className="m-0 fs-5 w-75 text-center rounded-3 mb-4 " id="desc" defaultValue={data[index].desc}/>
                                    </div>

                                    <div className="d-flex w-100">
                                        <p className="m-0 w-25 fs-5 me-2 fw-bold">Personnes max:</p>
                                        <input className="m-0 fs-5 w-75 text-center rounded-3 mb-3 fw-bold" id="maxPeople" defaultValue={data[index].maxPeople} />                                    
                                    </div>
                                    <div className="d-flex justify-content-between w-100">
                                        <Button className="bg-danger text-white fw-bold me-1 w-50 mt-5" onClick={() =>setOpenChangevalue(false)}>Annuler</Button>
                                        <Button className="bg-primary text-white fw-bold w-50 ms-1 mt-5" onClick={() => handleSearch(data[index]._id)}>Valider</Button>
                                    </div>
                                    <div className="d-flex justify-content-center w-100">
                                    <Button className="bg-warning text-white fw-bold w-50  mt-5" onClick={() => handleDelete(data[index]._id)}>Supprimer cette chambre</Button>
                                    </div>
                                </div>       
                            </div>
                    </div>
                    
                    <span className="fs-5 fw-bold text-primary ms-4 ">Chambres&Resérvation</span>
                    <div className="d-flex justify-content-between mx-5 mt-3 ">
                                    <div className="d-flex flex-column">
                                    {data[index].roomNumbers.map((roomNumber)=>
                                    
                                        
                                    isAvailable(roomNumber) ?
                                    <div className="rounded p-3 px-5 mb-4 d-flex flex-column justify-content-between align-items-center bg-primary bg-opacity-25 w-75  ms-5 mt-1">
                                     <Dropdown >
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" className='d-flex justify-content-center bg-white border border-warning border-3 w-100 py-2 px-2'>
                                            <p className='m-0 ' ><FaCalendarAlt className='me-1 mb-1  '  /> {`${format(date[0].startDate, "dd/MM/yyyy")} à ${format(date[0].endDate, "dd/MM/yyyy")}`}</p>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu >
                                            
                                                <DateRange
                                                    editableDateInputs={true}
                                                    onChange={item => setDates([item.selection])}
                                                    moveRangeOnFirstSelection={true}
                                                    ranges={date}
                                                    
                                                    
                                                />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <p className="fw-bold fs-3 text-center text-black mt-2 mb-3 text-white">{roomNumber.number}</p> 
                                     
                                    <Button className=' bg-primary text-center text-white w-75  m-2 ' onClick={()=> {handleChange(data[index].title, data[index]._id, roomNumber._id, data[index].price)}}  >Réservez!</Button>
                                    { roomNumber.unavailableDates.map((element)=>
                                    <div className=" d-flex flex-column text-center mb-4 px-3 m-0 border border-white bg-white mt-5 border-4">
                                        <p>Réservée du <span className="fw-bold">{format(element[0][0], 'dd/MM/yyyy')}</span> au <span className="fw-bold">{format(element[0][element[0].length-1], 'dd/MM/yyyy')}</span> par <span className="fw-bold">{element[1]}</span></p>
                                        {new Date().getTime()>element[0][element[0].length-1] && <Button className="rounded bg-danger text-white fw-bold mt-2" onClick={()=>deleteRoomStatus(roomNumber._id, element)} >Supprimer</Button>}
                                        {new Date().getTime()<element[0][element[0].length-1] && element[1]=== user.username && <Button className="rounded bg-danger text-white fw-bold mt-2" onClick={()=>deleteRoomStatus(roomNumber._id, element)} >Annuler votre réservation</Button>}
                                     </div>
                                    )}
                                    </div>:
                                    <div className="rounded p-3 d-flex flex-column justify-content-between align-items-center bg-danger bg-opacity-25 w-75  ms-5 mt-1">
                                        
                                        <Dropdown >
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" className='d-flex justify-content-center bg-white border border-warning border-3 w-100 py-2 px-2'>
                                                <p className='m-0 ' ><FaCalendarAlt className='me-1 mb-1  '  /> {`${format(date[0].startDate, "dd/MM/yyyy")} à ${format(date[0].endDate, "dd/MM/yyyy")}`}</p>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu >
                                                
                                                    <DateRange
                                                        editableDateInputs={true}
                                                        onChange={item => setDates([item.selection])}
                                                        moveRangeOnFirstSelection={true}
                                                        ranges={date}
                                                        
                                                        
                                                    />
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <p className="fw-bold fs-3 text-center text-black mt-2 mb-3 text-white">{roomNumber.number}</p> 
                                        
                                        <Button className=' bg-primary text-center text-white w-75 mb-5 m-2 ' onClick={()=> handleClick(roomNumber._id)}  >Réservez!</Button>
                                    { roomNumber.unavailableDates.map((element)=>
                                        <div className="d-flex flex-column text-center mb-4 px-3 m-0 border border-white bg-white border-4">
                                            <p>Réservée du <span className="fw-bold">{format(element[0][0], 'dd/MM/yyyy')}</span> au <span className="fw-bold">{format(element[0][element[0].length-1], 'dd/MM/yyyy')} </span> par <span className="fw-bold">{element[1]}</span></p> 
                                        {new Date().getTime()>element[0][element[0].length-1] && <Button className="rounded bg-danger text-white fw-bold mt-2" onClick={()=>deleteRoomStatus(roomNumber._id, element)} >Supprimer</Button>}
                                        {new Date().getTime()<element[0][element[0].length-1] && element[1]=== user.username && <Button className="rounded bg-danger text-white fw-bold mt-2" onClick={()=>deleteRoomStatus(roomNumber._id, element)} >Annuler votre réservation</Button>}
                                        </div>
                                        )}
                                        
                                        
                                    </div>
                                                
                                                )}
                                            </div>

                            
                        
                    </div>
                    
                </Container>
                </div> 
                }
                {openReserve&&
                <div className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center z-2" style={{ backgroundColor: 'rgba(0, 0, 0, 0.418)' }}>
                <Row className="justify-content-center w-100">
                  <Col xs={12} sm={10} md={8} lg={6} xl={5}>
                    <div className="p-4 rounded shadow-lg" style={{ backgroundColor: '#f8f9fa', maxWidth: '100%', minWidth: '300px' }}>
                      <h3 className="text-center mb-4" style={{ fontWeight: 'bold', fontSize: '1.8rem', color: '#333' }}>
                        Détail de la réservation
                      </h3>
              
                      <Form onSubmit={ (e) => handleClick(e)}>
                        {/* Nom */}
                        <Form.Group controlId="nom">
                          <Form.Label>Nom</Form.Label>
                          <Form.Control
                            type="text"
                            name="nom"
                            value={formData.nom}
                            onChange={handleChangeForm}
                            placeholder="Entrez le nom du client"
                            required
                            className="mb-3"
                          />
                        </Form.Group>
              
                        {/* Prénom */}
                        <Form.Group controlId="prenom">
                          <Form.Label>Prénom</Form.Label>
                          <Form.Control
                            type="text"
                            name="prenom"
                            value={formData.prenom}
                            onChange={handleChangeForm}
                            placeholder="Entrez le prénom du client"
                            required
                            className="mb-3"
                          />
                        </Form.Group>
              
                        {/* Email */}
                        <Form.Group controlId="email">
                          <Form.Label>Email (optionnel)</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChangeForm}
                            placeholder="Entrez l'email du client"
                            className="mb-3"
                          />
                        </Form.Group>
              
                        {/* Téléphone */}
                        <Form.Group controlId="telephone">
                          <Form.Label>Téléphone (optionnel)</Form.Label>
                          <Form.Control
                            type="tel"
                            name="telephone"
                            value={formData.telephone}
                            onChange={handleChangeForm}
                            placeholder="Entrez le numéro de téléphone du client"
                            className="mb-4"
                          />
                        </Form.Group>

                        {/* Boutons Annuler et Valider */}
                            <div className="d-flex justify-content-between">
                                <Button
                                variant="secondary"
                                onClick={() => {
                                    // Action pour l'annulation (réinitialiser le formulaire ou rediriger l'utilisateur)
                                    setFormData({
                                    nom: '',
                                    prenom: '',
                                    email: '',
                                    telephone: ''
                                    });
                                    setOpenReserve(false)
                                }}
                                className="bg-danger text-white"
                                style={{
                                    fontSize: '1.1rem',
                                    fontWeight: 'bold',
                                    padding: '10px 20px',
                                }}
                                >
                                Annuler
                                </Button>
                                
                                <Button
                                type="submit"
                                variant="primary"
                                className="bg-primary text-white"
                                style={{
                                    fontSize: '1.1rem',
                                    fontWeight: 'bold',
                                    padding: '10px 20px',
                                    borderColor: '#007bff',
                                }}
                                >
                                Valider <br /> (Appuyez 2 fois!)
                                </Button>
                            </div>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
              }
        </div>
    )
}